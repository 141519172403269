import React from "react";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import LinkToTour from "../components/LinkToTour";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import ParentRouteHandler from "../components/ParentRouteHandler";

const ArtistGuides = ({ location, data }) => {
  const isNavigatedFromNavMenu = location.search.includes("from-nav=1");

  const Container = styled.div`
    h2.small {
      font-size: 30px;
    }

    .intro {
      font-size: 16px;
      margin-bottom: 10px;
      margin-top: 16px;
    }

    .guides {
      margin-bottom: 42px;
    }
  `;

  let artistPdfs = [];

  data.pdfs.edges.map(({ node }) => {
    let item;
    if (node.relationships.field_pdf_asset) {
      item = node.relationships.field_pdf_asset;
    }
    if (node.field_pdf_category === "artist") {
      artistPdfs.push({
        id: node.id,
        label: node.title,
        text: item ? "View" : "Coming Soon",
        link: item ? item.localFile.publicURL : "",
        newTab: item ? true : false,
      });
    }
  });

  return (
    <Container>
      <Seo title="Artist Guides" />
      {isNavigatedFromNavMenu && <ParentRouteHandler title={"back"} />}

      <div className="guides">
        <HeadingWithSub main={"Artist Guides"} h2={true} />

        <p className="intro">
          Learn about artists in the expansive presentations featured in the
          skylit galleries on the third floor. Go in depth with extended content
          on each artwork.
        </p>

        {artistPdfs.map((guide) => (
          <LinkToTour
            key={guide.id}
            title={guide.label}
            subtitle={guide.text}
            link={guide.link}
            isDownload={false}
            displayImage={false}
            newTab={guide.newTab}
            isPdf={true}
            originPath="/artistguides"
            origin="Artist Guides"
            category="Artist Guides"
          />
        ))}
      </div>
    </Container>
  );
};

export default ArtistGuides;

export const query = graphql`
  {
    pdfs: allNodeMobilePdf {
      edges {
        node {
          ...MobilePDFQuery
        }
      }
    }
  }
`;
